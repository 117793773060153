import React from "react";

export default function TodayDate() {
  const renderDate = () => {
    const date = new Date();
    const options = { day: "numeric", month: "long", year: "numeric", weekday: "long" };
    return formatDate(date, options);
  };

  const formatDate = (date, options) => {
    return new Intl.DateTimeFormat(undefined, options).format(date);
  };

  return (
    <div>
      <h1>Today is: {renderDate()}</h1>
    </div>
  );
}
