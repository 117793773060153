import About from "../components/commands/About.tsx";
import Bio from "../components/commands/Bio.tsx";
import Contact from "../components/commands/Contact.tsx";
import Help from "../components/commands/Help.tsx";
import NotFound from "../components/commands/NotFound.tsx";
import Skills from "../components/commands/Skills.tsx";
import Form from "../components/commands/Form.tsx";
import Projects from "../components/commands/Projects.tsx";
import { CmdHistory } from "../type";

function renderCmd(cmd: string): CmdHistory {
    const lowerCmd = cmd.toLowerCase();
	switch (lowerCmd) {
        case "list":
        case "help":
		case "commands":
		case "command":
            return {
                cmd: lowerCmd,
                Component: Help,
                time: "",
            };
		case "bio":
			return {
                cmd: lowerCmd,
				Component: Bio,
				time: "",
			};

		case "skill":
		case "skills":
			return {
                cmd: lowerCmd,
				Component: Skills,
				time: "",
			};
		case "contact":
			return {
                cmd: lowerCmd,
				Component: Contact,
				time: "",
			};
		case "about": {
			return {
                cmd: lowerCmd,
				Component: About,
				time: "",
			};
            
		}
        case "form": {
            return {
                cmd: lowerCmd,
                Component: Form,
                time: "",
            };
		}
		case "project":
		case "proje":
		case "projects": {
            return {
                cmd: lowerCmd,
                Component: Projects,
                time: "",
            };
		}
        default:
            return {
                cmd: lowerCmd,
                Component: NotFound,
                time: "",
            };
	}
}

export default renderCmd;