import React, { useState, useRef } from "react";
import emailjs from "@emailjs/browser";

export default function Form() {
  const [showContactForm, setShowContactForm] = useState(false);
  const [submitButtonText, setSubmitButtonText] = useState("Send");
  const [submitButtonColor, setSubmitButtonColor] = useState("bg-blue-500");
  const formRef = useRef<HTMLFormElement | null>(null);

  const toggleContactForm = () => {
    setShowContactForm((prev) => !prev);
    if (!showContactForm) {
      document.body.style.overflow = "hidden"; // Disable scrolling when form is open
    } else {
      document.body.style.overflow = ""; // Enable scrolling when form is closed
    }
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault(); // Prevent default form submission
    const nameInput = document.getElementById(
      "name"
    ) as HTMLInputElement | null;
    const emailInput = document.getElementById(
      "email"
    ) as HTMLInputElement | null;
    const messageInput = document.getElementById(
      "message"
    ) as HTMLTextAreaElement | null;

    if (!nameInput || !emailInput || !messageInput) {
      console.error("One or more input elements not found.");
      return;
    }

    if (!nameInput.value || !emailInput.value || !messageInput.value) {
      alert("Please fill in all required fields.");
      return;
    }

    if (formRef.current) {
      emailjs
        .sendForm("service_krxydyk", "template_fmv46pd", formRef.current, {
          publicKey: "BQnbhTlRhufr7jeat",
        })
        .then(
          () => {
            console.log("SUCCESS!");
            setSubmitButtonText("OK!");
            setSubmitButtonColor("bg-green-500");
            setTimeout(() => {
              toggleContactForm(); // Close the form after 2 seconds
              setSubmitButtonText("Send");
              setSubmitButtonColor("bg-blue-500");
              formRef.current?.reset(); // Clear input fields after submission
            }, 2000);
          },
          (error) => {
            console.log("FAILED...", error.text);
          }
        );
    } else {
      console.error("Form reference not found.");
    }
  };
  return (
    <div className="w-11/12 mx-auto">
      {/* Button to toggle contact form */}
      <div className="ml-5">
        <button
          onClick={toggleContactForm}
          className={`font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline align-items-center ${submitButtonColor} text-white hover:bg-blue-700`}
        >
          Open Contact Form
        </button>
      </div>

      {/* Conditional rendering of contact form */}
      {showContactForm && (
        <div className="fixed inset-0 flex justify-center items-center bg-gray-800 bg-opacity-50 z-50">
          <div className="bg-white p-6 rounded-lg">
            <h2 className="text-2xl mb-4 text-gray-900">Contact Form</h2>
            <form ref={formRef} onSubmit={handleSubmit}>
              <div className="flex mb-4">
                <div className="mb-4 flex-1 mr-4">
                  <label
                    htmlFor="name"
                    className="block text-gray-700 text-sm font-bold mb-2"
                  >
                    Name
                  </label>
                  <input
                    type="text"
                    id="name"
                    name="user_name"
                    placeholder="Enter your name"
                    className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                    autoFocus
                  />
                </div>
                <div className="mb-4 flex-1">
                  <label
                    htmlFor="email"
                    className="block text-gray-700 text-sm font-bold mb-2"
                  >
                    Email
                  </label>
                  <input
                    type="email"
                    id="email"
                    name="user_email"
                    placeholder="Enter your email"
                    className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  />
                </div>
              </div>
              <div className="mb-6">
                <label
                  htmlFor="message"
                  className="block text-gray-700 text-sm font-bold mb-2"
                >
                  Message
                </label>
                <textarea
                  id="message"
                  placeholder="Enter your message"
                  name="message"
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  rows={5}
                ></textarea>
              </div>
              <div className="flex items-center justify-between">
                <button
                  type="submit"
                  value="Send"
                  className="font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline bg-blue-500 text-white hover:bg-blue-700"
                >
                  {submitButtonText}
                </button>
                <button
                  onClick={toggleContactForm}
                  className="text-sm text-blue-500"
                >
                  Close
                </button>
              </div>
            </form>
          </div>
        </div>
      )}
    </div>
  );
}
