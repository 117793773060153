import React, { useEffect, useState } from "react";
import { GoVerified } from "react-icons/go";
import { SiGithub, SiLinkedin, SiYoutube } from "react-icons/si";
import { LuMailPlus } from "react-icons/lu";

export default function Bio() {
    const birthYear = 1998;
    const currentYear = new Date().getFullYear();
    const age = currentYear - birthYear-1;

	const [animate, setAnimate] = useState(false);
	useEffect(() => {
		setAnimate(true);
	}, []);


	const links = [
		{
			title: "LinkedIn",
			Icon: SiLinkedin,
			href: "https://www.linkedin.com/in/yusufonaran/",
			text: "@yusufonaran",
		},
		{
			title: "e-mail",
			Icon: LuMailPlus,
			href: "mailto:yusufdeveloper@outlook.com",
			text: "Send an e-mail",
		},
		{
			title: "Github",
			Icon: SiGithub,
			href: "https://github.com/onaranyusuf",
			text: "@onaranyusuf",
		},
		{
			title: "Youtube",
			Icon: SiYoutube,
			href: "https://www.youtube.com/@YusufOnaran/videos",
			text: "@YusufOnaran",
		},


	];
	return (
		<div className="mt-2 mb-2 w-11/12 mx-auto">
			<div className="flex items-center gap-3 mb-3 animate-reveal-0.5">
				<img
					src="https://avatars.githubusercontent.com/u/108731953?v=4"
					alt="Yusuf Onaran"
					className="w-40 h-40 rounded-full border-2 border-yellow-200"
				/>
				<div>
					<div className="flex items-center gap-1">
						<h1 className="text-2xl">Yusuf Onaran</h1>
						<GoVerified className="text-blue-500 text-2xl" />
					</div>
					<p className="text-lg text-gray-400">Join the world {age} years ago.</p>
				</div>
			</div>
			<p className={` transform transition-all duration-700 ${
      animate ? "scale-100" : "scale-75"
    }`}>
            Hello, I'm Jr. Software Developer. I'm passionate about web development and love learning new things about coding.
			</p>
			<div className="grid grid-cols-2">
				{links.map(({ Icon, text, href, title }, index) => {
					return (
						<div className="flex items-center gap-1.5 mt-3" key={index}>
							<div className="text-lg flex items-center gap-1 text-yellow-200">
								<Icon className="mb-1.5"/>
								<h1>{title}</h1>
							</div>
							<a
								href={href}
								target="_blank"
								rel="noreferrer"
								className="flex-1 underline text-blue-300 "
							>
								{text}
							</a>
						</div>
					);
				})}
			</div>
		</div>
	);
}