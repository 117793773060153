import React, { useEffect, useRef, useState } from "react";
import BaseCmdInput from "./BaseCmdInput.tsx";
interface CmdUserInputProp {
	onSubmit: (cmd: string) => void;
}
export default function CmdUserInput({ onSubmit }: CmdUserInputProp) {
	const inputRef = useRef() as React.MutableRefObject<HTMLInputElement>;
	const [showFakeCursor, setShowFakeCursor] = useState(false);
	const [prevCmd, setPrevCmd] = useState<string[]>([]);
	const [prevCmdIndex, setPrevCmdIndex] = useState(-1);

	const handleSubmit = (e: React.ChangeEvent<HTMLFormElement>) => {
		e.preventDefault();
		const value = inputRef.current.value;
		onSubmit(value);
		if (value.trim()) {
			setPrevCmd((currentValue) => [value, ...currentValue]);
		}
		setPrevCmdIndex(-1);
	};
	useEffect(() => {
		const handleKeyDown = (e: KeyboardEvent) => {
			const key = e.key?.toLowerCase();
		  
			// Eğer pop-up içindeki inputa odaklanılmamışsa ve genel inputa yazı yazılıyorsa işleme devam et
			if (document.activeElement !== inputRef.current && key !== "arrowup" && key !== "arrowdown") {
			  return;
			}
		  
			if (key && key === "arrowup") {
			  e.preventDefault(); // Önceki sayfa hareketini engelle
			  setPrevCmdIndex((prevIndex) => Math.min(prevIndex + 1, prevCmd.length - 1));
			} else if (key && key === "arrowdown") {
			  e.preventDefault(); // Sonraki sayfa hareketini engelle
			  setPrevCmdIndex((prevIndex) => Math.max(prevIndex - 1, -1));
			}
		  };
		  
		  
	  
		document.addEventListener("keydown", handleKeyDown);
		return () => {
		  document.removeEventListener("keydown", handleKeyDown);
		};
	  }, [prevCmdIndex, prevCmd]);
	  

	useEffect(() => {
		const input = inputRef.current;
		let timeout: any = null;
		if (input) {
			input.value = prevCmd[prevCmdIndex] ? prevCmd[prevCmdIndex] : "";
			inputRef.current?.focus();
			timeout = setTimeout(
				() => inputRef.current.setSelectionRange(-1, -1),
				10
			);
		}
		return () => {
			clearTimeout(timeout);
		};
	}, [prevCmdIndex, prevCmd]);

	return (
		<BaseCmdInput
			Children={
				<form onSubmit={handleSubmit} className="w-full flex items-center">
					{showFakeCursor && (
						<div className=" w-1 h-5 animate-blink bg-white translate-y-2 -translate-x-3"></div>
					)}
					<input
						className="translate-y-2 -translate-x-3 bg-inherit w-full focus:outline-none placeholder:text-gray-600 placeholder:tracking-wider"
						autoFocus
						onBlur={() => {
							setShowFakeCursor(true);
						}}
						onFocus={() => {
							setShowFakeCursor(false);
						}}
						ref={inputRef}
						placeholder="type list, bio, projects, skills, contact, form, about..."
					/>
				</form>
			}
		/>
	);
}