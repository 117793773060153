import React, { useEffect, useState } from "react";
import { SiNetlify, SiReact, SiTailwindcss } from "react-icons/si";

export default function About() {
  const techs = [
    { Icon: SiReact, color: "text-blue-500", duration: "duration-500" },
    { Icon: SiTailwindcss, color: "text-blue-300", duration: "duration-700" },
    { Icon: SiNetlify, color: "text-green-400", duration: "duration-1000" },
  ];
  const [animate, setAnimate] = useState(false);
  useEffect(() => {
    setAnimate(true);
  }, []);
  return (
    <div>
      <h1 className="text-2xl text-yellow-200 animate-reveal-0.5">About website</h1>
      <p className="text-lg mb-3 animate-reveal-0.5">
        This project is a simple portfolio application that can run its own
        terminal-style commands. By entering different commands, users can get
        information about me, view my skills, get in touch, or experience other
        special commands.
      </p>
      <h1 className="text-2xl text-yellow-200 animate-reveal-0.7">
        Tech
      </h1>
      <p className="text-lg animate-reveal-0.7">
        This site is pure written with React and Tailwindcss only. Deployed on Netlify.
      </p>
      <div className="flex gap-5 text-4xl">
        {techs.map(({ Icon, color, duration }, index) => {
          return (
            <Icon
              key={index}
              className={`${color} ${
                animate ? "scale-100" : "scale-0"
              } transform transition-all ${duration}`}
            />
          );
        })}
      </div>
    </div>
  );
}
