import React, { useEffect, useState } from "react";
import {
  FaReact,
  FaJava,
  FaHtml5,
  FaCss3,
  FaPython,
  FaDocker,
} from "react-icons/fa";
import {
  SiApachemaven,
  SiGithub,
  SiNetlify,
  SiReact,
  SiTailwindcss,
  SiSelenium,
  SiPostman,
  SiTypescript,
} from "react-icons/si";
import { TbWorldWww } from "react-icons/tb";
import { FaXTwitter } from "react-icons/fa6";

export default function Projects() {
  const [animate, setAnimate] = useState(false);
  useEffect(() => {
    setAnimate(true);
  }, []);

  const projects = [
    {
      id: 1,
      title: "React Portfolio",
      imageSrc: "/deneme.jpg",
      description: "A portfolio website built using React.js and Tailwindcss.",
      technologies: [<FaReact />, <SiTypescript />, <FaCss3 />, <FaHtml5 />],
      code: "#",
      demo: "https://yusufonaran.tech/",
    },
    {
      id: 2,
      title: "Weather App",
      imageSrc: "/weatherapp.jpg",
      description:
        "A simple application that provides weather service via API.",
      technologies: [<SiReact />, <SiTailwindcss />, <SiNetlify />],
      code: "https://github.com/onaranyusuf/havadurumuapi",
      demo: "https://weather-yo.netlify.app/",
    },
    {
      id: 3,
      title: "Shopping Cart Application",
      imageSrc: "/cart.jpg",
      description:
        "This project includes a backend application that provides a shopping cart functionality.",
      technologies: [
        <FaJava />,
        <SiApachemaven />,
        <FaDocker />,
        <SiPostman />,
      ],
      code: "https://github.com/onaranyusuf/trendyol-backend-bootcamp-final-case",
    },
    {
      id: 4,
      title: "Gemini AI Clone",
      imageSrc: "/gemini.jpg",
      description:
        "A Gemini AI clone application using the Gemini API.",
      technologies: [<SiReact />, <SiTailwindcss />, <SiNetlify />],
      code: "https://github.com/onaranyusuf/geminiclone",
      demo: "https://geminiclone-yo.netlify.app/",
    },
    {
      id: 5,
      title: "Twitter (X) Automation",
      imageSrc: "/xtwitter.jpg",
      description:
        "App uses Selenium to automate interactions on Twitter. Log in with multiple user accounts, like, retweet and add comments.",
      technologies: [<FaPython />, <SiSelenium />, <FaXTwitter />],
      code: "https://github.com/onaranyusuf/Twitter-Automation-Script",
    },
  ];

  return (
    <div className="mt-2 mb-2 w-11/12 mx-auto">
      <div
        className={` transform transition-all duration-700 ${
          animate ? "scale-100" : "scale-50"
        }`}
      >
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
          {projects.map((project) => (
            <div
              key={project.id}
              className="bg-white bg-opacity-10 p-4 shadow rounded flex"
            >
              <img
                src={project.imageSrc}
                alt={project.title}
                className="w-24 h-24 mr-4 rounded-full"
              />
              <div>
                <h3 className="text-lg font-bold text-yellow-200">
                  {project.title}
                </h3>
                <p className="text-gray-400 mb-2 text-base">
                  {project.description}
                </p>
                <div className="flex items-center gap-2 mb-2">
                  {project.technologies.map((icon, index) => (
                    <div key={index}>{icon}</div>
                  ))}
                </div>
                <div className="grid grid-cols-1 md:grid-cols-2">
                  <div className="flex items-center gap-1">
                    <SiGithub className="mb-1" />
                    <a
                      href={project.code}
                      target="_blank"
                      rel="noreferrer"
                      className="text-blue-300 text-lg"
                    >
                      Code
                    </a>
                  </div>
                  {project.demo && ( // Demo linki varsa göster
                    <div className="flex items-center gap-1">
                      <TbWorldWww className="mb-1" />
                      <a
                        href={project.demo}
                        target="_blank"
                        rel="noreferrer"
                        className="text-blue-300 text-lg"
                      >
                        Demo
                      </a>
                    </div>
                  )}
                </div>
              </div>
            </div>
          ))}
        </div>
        <div className="flex items-center justify-center mt-2">
          <a
            href="https://github.com/onaranyusuf?tab=repositories"
            target="_blank"
            rel="noreferrer"
          >
            <button
              className={`font-bold py-1 px-4 rounded focus:outline-none focus:shadow-outline text-sm text-gray-900 bg-yellow-200 flex items-center gap-1`}
            >
              <SiGithub className="text-gray-900 mb-1" />
              <span>See more projects!</span>
            </button>
          </a>
        </div>
      </div>
    </div>
  );
}
