import React from "react";
import ProgressBar from "../ProgressBar.tsx";
import {
	SiPython,
	SiAutocad,
	SiHtml5,
	SiCss3,
	SiReact


} from "react-icons/si";

import {

	FaVuejs,

} from "react-icons/fa";

export default function Skills() {
	const listOfSkills = [
		{
			Icon: SiPython,
			barWidth: "w-16/20",
			percentageValue: "80%",
			text: "Python",
		},
		{
			Icon: SiAutocad,
			barWidth: "w-17/20",
			percentageValue: "85%",
			text: "Autocad",
		},
		{
			Icon: SiHtml5,
			barWidth: "w-18/20",
			percentageValue: "90%",
			text: "Html",
		},
		{
			Icon: SiCss3,
			barWidth: "w-15/20",
			percentageValue: "75%",
			text: "Css",
		},
		{
			Icon: SiReact,
			barWidth: "w-14/20",
			percentageValue: "70%",
			text: "React.js",
		},
		{
			Icon: FaVuejs,
			barWidth: "w-12/20",
			percentageValue: "60%",
			text: "Vue.js",
		},
	];

	return (
		<div>
			{listOfSkills.map(({ Icon, barWidth, percentageValue, text }, index) => {
				return (
					<ProgressBar
						Icon={Icon}
						barWidth={barWidth}
						percentageValue={percentageValue}
						key={index}
						text={text}
					/>
				);
			})}
		</div>
	);
}